import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, keywords, image, url }) => {
  const siteTitle = "Axellier";
  const defaultDescription =
    "Axellier - Empowering brands with AI solutions, strategic marketing, and insightful analytics to drive digital growth.";

  const defaultImage = "https://axellier.com/logo512.png";
  const defaultUrl = "https://axellier.com";

  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{`${title} | ${siteTitle}`}</title>

      {/* Standard Meta */}
      <meta name='robots' content='index, follow' />
      <meta name='description' content={description || defaultDescription} />
      <meta
        name='keywords'
        content={
          keywords || "creative agency, digital solutions, corporate services"
        }
      />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, shrink-to-fit=no'
      />

      {/* Open Graph (OG) Meta Tags for Social Media Sharing */}
      <meta property='og:title' content={`${title} | ${siteTitle}`} />
      <meta
        property='og:description'
        content={description || defaultDescription}
      />
      <meta property='og:image' content={image || defaultImage} />
      <meta property='og:url' content={url || defaultUrl} />
      <meta property='og:type' content='website' />

      {/* Twitter Card Meta Tags */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={`${title} | ${siteTitle}`} />
      <meta
        name='twitter:description'
        content={description || defaultDescription}
      />
      <meta name='twitter:image' content={image || defaultImage} />

      {/* Canonical URL */}
      <link rel='canonical' href={url || defaultUrl} />

      {/* Structured Data - JSON-LD */}
      <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          url: defaultUrl,
          name: siteTitle,
          description: description || defaultDescription,
          image: image || defaultImage,
        })}
      </script>
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
};

export default SEO;
