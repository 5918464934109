import React from "react";
import { FaAngleDown, FaAngleRight, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { featureFlags } from "../../utils/config";

const isMobile = window.innerWidth <= 820;

const gridStyles = !isMobile
  ? {
      display: "flex",
      gap: "16px",
      transform: "translateX(-50%)",
    }
  : {};

const SoftForgeDropdown = () => {
  return (
    <ul className='axil-submenu' style={gridStyles}>
      {featureFlags.SoftForge && (
        <li className='menu-item-has-children'>
          {/* <ServiceMainMenuItem title='SoftForge 💻' /> */}
          <ul className='axil-submenu-inner'>
            <li>
              <Link to={"/service-details/softforge/"}>
                Intelligent AI-Powered Solutions
              </Link>
            </li>
            <li>
              <Link to={"/service-details/softforge/"}>
                Custom Website Development
              </Link>
            </li>
            <li>
              <Link to={"/service-details/softforge/"}>
                Tailored Mobile App Development
              </Link>
            </li>
            <li>
              <Link to={"/service-details/softforge/"}>
                Advanced Digital Security Audits
              </Link>
            </li>
          </ul>
        </li>
      )}
    </ul>
  );
};

const MarketechDropdown = () => {
  return (
    <ul className='axil-submenu' style={gridStyles}>
      {featureFlags.Marketech && (
        <li className='menu-item-has-children'>
          {/* <ServiceMainMenuItem title='Marketech 🚀' /> */}
          <ul className='axil-submenu-inner'>
            <li>
              <Link to={"/service-details/marketech/"}>
                Social Media Strategy & Advertisements
              </Link>
            </li>
            <li>
              <Link to={"/service-details/marketech/"}>
                SEO & Retargeting Campaigns
              </Link>
            </li>
            <li>
              <Link to={"/service-details/marketech/"}>
                Email & Video Marketing
              </Link>
            </li>
            <li>
              <Link to={"/service-details/marketech/"}>
                Influencer Partnerships
              </Link>
            </li>
          </ul>
        </li>
      )}
    </ul>
  );
};

const InsightifyDropdown = () => {
  return (
    <ul className='axil-submenu' style={gridStyles}>
      {featureFlags.Insightify && (
        <li className='menu-item-has-children'>
          {/* <ServiceMainMenuItem title='Insightify 🔍' /> */}
          <ul className='axil-submenu-inner'>
            <li>
              <Link to={"/service-details/insightify/"}>
                In-depth Market Research & Analysis
              </Link>
            </li>
            <li>
              <Link to={"/service-details/insightify/"}>
                Sentiment & Competitor Analysis
              </Link>
            </li>
            <li>
              <Link to={"/service-details/insightify/"}>
                Conversion Rate Optimization Strategies
              </Link>
            </li>
            <li>
              <Link to={"/service-details/insightify/"}>
                Customer Journey Mapping & Analysis
              </Link>
            </li>
          </ul>
        </li>
      )}
    </ul>
  );
};

const Nav = () => {
  const promotionItemStyle = {
    position: "relative",
    display: "flex", // Changed to flex for alignment of text and icon
    alignItems: "center",
    margin: "0 15px",
  };
  
  const promotionBadgeStyle = !isMobile
    ? {
        position: "absolute",
        top: "6px",
        right: "-12px",
        backgroundColor: "gold",
        color: "white",
        padding: "3px",
        borderRadius: "50%",
        fontSize: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
    : {
        // Removed position absolute for mobile to keep it inline with text
        backgroundColor: "gold",
        color: "white",
        padding: "3px",
        borderRadius: "50%",
        fontSize: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "8px", // Space between text and star for mobile view
      };
  
  const badgeIconStyle = {
    fontSize: "12px",
  };
  
  

  return (
    <nav className='mainmenu-nav'>
      <ul className='mainmenu'>
        <li className='menu-item-has-children'>
          <Link to='#'>
            SoftForge <FaAngleDown />
          </Link>
          <SoftForgeDropdown />
        </li>
        <li className='menu-item-has-children'>
          <Link to='#'>
            Marketech <FaAngleDown />
          </Link>
          <MarketechDropdown />
        </li>
        <li className='menu-item-has-children'>
          <Link to='#'>
            Insightify <FaAngleDown />
          </Link>
          <InsightifyDropdown />
        </li>
        <li>
          <Link to={"/projects"}>Projects</Link>
        </li>
        <li>
          <Link to={"/about-us"}>About Us</Link>
        </li>
        <li>
          <Link to={"/contact"}>Contact</Link>
        </li>
        <li style={promotionItemStyle}>
          <Link to={"/promotions"}>Promotions</Link>
          <div style={promotionBadgeStyle}>
            <FaStar style={badgeIconStyle} />
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
